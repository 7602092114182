<template>
  <div class="modal-mask" v-if="visible">
    <div class="modal-container">
      <div class="modal-header">
        <h2>Login to Your Account</h2>
        <button class="close-button" @click="close">
          ×
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="handleLogin">
          <div class="input-group">
            <label for="email">Username/Email</label>
            <input type="text" id="email" v-model="email" required />
          </div>
          <div class="input-group">
            <label for="password">Password</label>
            <div class="password-container">
              <input
                :type="showPassword ? 'text' : 'password'"
                id="password"
                v-model="password"
                required
              />
              <span
                class="toggle-password"
                @click="togglePassword"
              >
                <i
                  :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                ></i>
              </span>
            </div>
          </div>
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <button type="submit" class="login-button">Login</button>
        </form>
        <div class="login-actions">
          <a href="#" @click="openForgetPassword" class="action-link"
            >Forgot Password?</a
          >
          <a href="#" @click="openRegister" class="action-link">Register</a>
        </div>
      </div>
    </div>
  </div>

  <!-- 注册弹窗 -->
  <RegistrationComponent :visible="isRegisterVisible" @close="closeRegister" />
  <!-- 忘记密码弹窗 -->
  <ForgetPasswordComponent
    :visible="isForgetPasswordVisible"
    @close="closeForgetPassword"
  />
</template>

<script>
import RegistrationComponent from "@/components/RegistrationComponent.vue";
import ForgetPasswordComponent from "@/components/ForgetPasswordComponent.vue";

export default {
  name: "LoginComponent",
  components: { ForgetPasswordComponent, RegistrationComponent },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    emits: [close],
  },
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      errorMessage: null,
      isRegisterVisible: false,
      isForgetPasswordVisible: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    openRegister() {
      this.close();
      this.isRegisterVisible = true;
    },
    closeRegister() {
      this.isRegisterVisible = false;
    },
    openForgetPassword() {
      this.close();
      this.isForgetPasswordVisible = true;
    },
    closeForgetPassword() {
      this.isForgetPasswordVisible = false;
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    handleLogin() {
      let loader = this.$loading.show();
      this.$store
        .dispatch("loginUser", {
          email: this.email,
          password: this.password,
        })
        .then(() => {
          this.close(); // 登录成功后关闭弹窗
        })
        .catch((error) => {
          this.errorMessage = "Wrong username or password.";
          console.error("Login failed:", error);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  position: relative;
  color: black;
  background-color: #fff;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 20px 30px 20px;
  width: 400px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  padding-right: 24px;
}

.modal-header h2 {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  font-size: 14px;
  color: black;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
}

.password-container {
  position: relative;
  width: 100%;
}

.password-container input {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  color: #666;
}

.toggle-password:hover {
  color: #333;
}

/* 隐藏浏览器原生的密码切换按钮 */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.close-button {
  position: absolute;
  right: -10px;
  top: -5px;
  background: none;
  border: none;
  font-size: 24px;
  line-height: 1;
  color: #666;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
  font-family: Arial, sans-serif;
}

.close-button:hover {
  color: #333;
}

.close-button:focus {
  outline: none;
}

.login-button {
  font-size: 13px;
  background-color: white;
  color: #1d2e79;
  border: 2px solid #1d2e79;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
}

.login-button:hover {
  color: white;
  background-color: #1d2e79 /* 鼠标悬停时背景颜色 */
}

.login-button:active {
  color: white;
  background-color: #2a3daa; /* 按钮被按下时背景颜色 */
}

.login-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 5px;
}

.action-link {
  font-size: 13px;
  color: #1d2e79;
  text-decoration: none;
  cursor: pointer;
}

.action-link:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  font-size: 13px;
  margin-top: 10px;
}
</style>
