<template>
  <div class="modal-mask" v-if="visible">
    <div class="modal-container">
      <div class="modal-header">
        <h2>Register a New Account</h2>
        <button class="close-button" @click="close">
          ×
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="handleRegister">
          <div class="input-group">
            <label for="register-email">Email</label>
            <input
              type="email"
              id="register-email"
              v-model="email"
              required
            />
          </div>

          <div class="input-group">
            <label for="register-password">
              Password
              <span class="password-info">
                <i class="fas fa-question-circle"></i>
                <div class="password-tooltip">
                  <div class="tooltip-item" :class="{ 'valid': lengthValid }">
                    8-16 characters
                  </div>
                  <div class="tooltip-item" :class="{ 'valid': uppercaseValid }">
                    At least one uppercase letter
                  </div>
                  <div class="tooltip-item" :class="{ 'valid': lowercaseValid }">
                    At least one lowercase letter
                  </div>
                  <div class="tooltip-item" :class="{ 'valid': numberValid }">
                    At least one number
                  </div>
                  <div class="tooltip-item" :class="{ 'valid': specialCharValid }">
                    At least one special character
                  </div>
                </div>
              </span>
            </label>
            <div class="password-container">
              <input
                :type="showPassword ? 'text' : 'password'"
                id="register-password"
                v-model="password"
                @input="validatePassword"
                required
              />
              <span
                class="toggle-password"
                @click="togglePassword('register-password')"
              >
                <i
                  :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                ></i>
              </span>
            </div>
            <div class="password-strength">
              <div class="strength-bar">
                <div 
                  class="strength-progress"
                  :style="{ width: passwordStrength + '%' }"
                  :class="strengthClass"
                ></div>
              </div>
            </div>
          </div>

          <div class="input-group">
            <label for="confirm-password">Confirm Password</label>
            <div class="password-container">
              <input
                :type="showConfirmPassword ? 'text' : 'password'"
                id="confirm-password"
                v-model="confirmPassword"
                required
              />
              <span
                class="toggle-password"
                @click="togglePassword('confirm-password')"
              >
                <i
                  :class="showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                ></i>
              </span>
            </div>
          </div>

          <div class="input-group">
            <label for="verification-code">Verification Code</label>
            <div class="verification-container">
              <input
                type="text"
                id="verification-code"
                v-model="verificationCode"
                required
                class="verification-input"
              />
              <button
                @click="sendVerificationCode"
                :disabled="disableResend"
                class="resend-button"
              >
                {{ resendButtonText }}
              </button>
            </div>
          </div>

          <div class="input-group">
            <label for="invite-code">Invitation Code</label>
            <input
              type="text"
              id="invite-code"
              v-model="invitationCode"
              placeholder="Optional"
              class="invite-input"
            />
          </div>

          <div class="terms-group">
            <input
              type="checkbox"
              id="agree-terms"
              v-model="agreeTerms"
              class="checkbox"
              @invalid.prevent
              novalidate
            />
            <label for="agree-terms">
              I accept the
              <a href="/terms-of-service" target="_blank" class="footer-link">
                Terms of Service
              </a>
              and
              <a href="/privacy-policy" target="_blank" class="footer-link">
                Privacy Policy
              </a>
              .
            </label>
          </div>

          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>

          <button type="submit" class="register-button">Register</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_BASE_URL } from "@/config";

export default {
  name: "RegistrationComponent",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    emits: [close],
  },
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      verificationCode: "",
      invitationCode: "",
      agreeTerms: false,
      resendTimeout: 60,
      disableResend: false,
      resendButtonText: "Send Code",
      showPassword: false,
      showConfirmPassword: false,
      errorMessage: null,
      lengthValid: false,
      uppercaseValid: false,
      lowercaseValid: false,
      numberValid: false,
      specialCharValid: false,
      passwordStrength: 0,
    };
  },
  computed: {
    strengthClass() {
      if (this.passwordStrength < 40) return 'weak';
      if (this.passwordStrength < 80) return 'medium';
      return 'strong';
    },
    strengthText() {
      if (this.passwordStrength < 40) return 'Weak';
      if (this.passwordStrength < 80) return 'Medium';
      return 'Strong';
    },
    passwordRequirements() {
      return [
        { valid: this.lengthValid },
        { valid: this.uppercaseValid },
        { valid: this.lowercaseValid },
        { valid: this.numberValid },
        { valid: this.specialCharValid }
      ];
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleRegister() {
      // Reset error message
      this.errorMessage = null;

      if (!(this.lengthValid && this.uppercaseValid && this.lowercaseValid && 
            this.numberValid && this.specialCharValid)) {
        this.errorMessage = "Please ensure all password requirements are met.";
        return;
      }
      
      if (this.password !== this.confirmPassword) {
        this.errorMessage = "Passwords do not match.";
        return;
      }
      
      if (!this.agreeTerms) {
        this.errorMessage = "Please accept the Terms of Service and Privacy Policy.";
        return;
      }
      
      let loader = this.$loading.show();
      axios
        .post(`${API_BASE_URL}/user/register`, {
          email: this.email,
          password: this.password,
          verificationCode: this.verificationCode,
          invitationCode: this.invitationCode || null,
        })
        .then((response) => {
          if (response.status === 201 && response.data && response.data.message) {
            alert(response.data.message);
            this.close();
          } else {
            this.errorMessage = "Registration failed. Please check your information and try again.";
          }
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 400:
                this.errorMessage = error.response.data.error || "Invalid information provided.";
                break;
              case 401:
                this.errorMessage = "Invalid verification code.";
                break;
              case 409:
                this.errorMessage = "Email already registered.";
                break;
              default:
                this.errorMessage = error.response.data.error || "Registration failed. Please try again.";
            }
          } else if (error.request) {
            this.errorMessage = "No response from server. Please try again later.";
          } else {
            this.errorMessage = "Failed to send registration request.";
          }
          console.error("Registration error:", error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    sendVerificationCode() {
      if (this.disableResend) return;

      this.disableResend = true;
      this.resendButtonText = `${this.resendTimeout} seconds`;

      axios
        .post(`${API_BASE_URL}/user/send-verification-code`, {
          email: this.email,
        })
        .then(() => {
          alert("Verification code sent. Please check your email.");
          this.startResendCountdown();
        })
        .catch(() => {
          this.errorMessage = "Failed to send verification code.";
          this.disableResend = false;
          this.resendButtonText = "Send Code";
        });
    },
    startResendCountdown() {
      const interval = setInterval(() => {
        this.resendTimeout -= 1;
        this.resendButtonText = `${this.resendTimeout} seconds`;

        if (this.resendTimeout <= 0) {
          clearInterval(interval);
          this.resendTimeout = 60;
          this.disableResend = false;
          this.resendButtonText = "Send Code";
        }
      }, 1000);
    },
    togglePassword(field) {
      if (field === "register-password") {
        this.showPassword = !this.showPassword;
      } else if (field === "confirm-password") {
        this.showConfirmPassword = !this.showConfirmPassword;
      }
    },
    validatePassword() {
      // 检查长度
      this.lengthValid = this.password.length >= 8 && this.password.length <= 16;
      
      // 检查大写字母
      this.uppercaseValid = /[A-Z]/.test(this.password);
      
      // 检查小写字母
      this.lowercaseValid = /[a-z]/.test(this.password);
      
      // 检查数字
      this.numberValid = /\d/.test(this.password);
      
      // 检查特殊字符
      this.specialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(this.password);
      
      // 计算密码强度
      const validCount = [
        this.lengthValid,
        this.uppercaseValid,
        this.lowercaseValid,
        this.numberValid,
        this.specialCharValid
      ].filter(Boolean).length;
      
      this.passwordStrength = (validCount / 5) * 100;
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  position: relative;
  color: black;
  background-color: #fff;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 20px 30px 20px;
  width: 400px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  padding-right: 24px; /* 为关闭按钮留出空间 */
}

.modal-header h2 {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.input-group {
  margin-bottom: 10px;
}

.input-group label {
  display: block;
  font-size: 14px;
  color: black;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  box-sizing: border-box;
}

.password-container {
  position: relative;
  width: 100%;
}

.password-container input {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
}

.password-strength {
  margin-top: 8px;
  width: 100%;
}

.strength-bar {
  width: 100%;
  height: 4px;
  background-color: #e1e4e8;
  border-radius: 2px;
  overflow: hidden;
}

.strength-progress {
  height: 100%;
  width: 0;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.strength-progress.weak {
  background-color: #ff4d4f;
}

.strength-progress.medium {
  background-color: #faad14;
}

.strength-progress.strong {
  background-color: #2d5016;
}

.error-message {
  color: red;
  font-size: 13px;
  margin-top: 10px;
}

.verification-container {
  display: flex;
  align-items: center;
}

.verification-input {
  margin-right: 10px;
}

.verification-input,
.resend-button {
  flex-grow: 1;
}

.resend-button {
  flex-basis: 30%; /* 按钮占据剩余空间 */
  white-space: nowrap; /* 防止按钮文字换行 */
  color: #1d2e79;
  background-color: white;
  border: 2px solid #1d2e79;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.resend-button:disabled {
  color: grey;
  border-color: grey;
  cursor: not-allowed;
}

.resend-button:not(:disabled):hover {
  color: white;
  background-color: #1d2e79 /* 鼠标悬停时背景颜色 */
}

.resend-button:not(:disabled):active {
  color: white;
  background-color: #2a3daa; /* 按钮被按下时背景颜色 */
}

.terms-group {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.terms-group label a {
  color: #1d2e79;
}

.terms-group label a:hover {
  text-decoration: underline;
}

.checkbox {
  margin-right: 10px;
}

.password-info {
  display: inline-block;
  margin-left: 5px;
  position: relative;
  color: #666;
}

.password-info i {
  font-size: 14px;
  cursor: help;
}

.password-tooltip {
  display: none;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
  background: white;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  padding: 10px;
  width: 250px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.password-info:hover .password-tooltip {
  display: block;
}

.tooltip-item {
  margin: 4px 0;
  color: #ff4d4f;
  font-size: 12px;
  position: relative;
  padding-left: 12px;
}

.tooltip-item::before {
  content: '•';
  position: absolute;
  left: 0;
  color: inherit;
}

.tooltip-item.valid {
  color: #2d5016;
}

.tooltip-item i {
  display: none;
}

.password-tooltip::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent #e1e4e8 transparent transparent;
}

.password-tooltip::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  color: #666;
}

.toggle-password:hover {
  color: #333;
}

/* 隐藏浏览器原生的密码切换按钮 */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.register-button {
  font-size: 12px;
  width: 100%;
  padding: 10px;
  background-color: white;
  color: #1d2e79;
  border: 2px solid #1d2e79;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 7px;
}

.register-button:disabled {
  color: grey;
  border-color: grey;
  cursor: not-allowed;
}

.register-button:not(:disabled):hover {
  color: white;
  background-color: #1d2e79; /* 鼠标悬停时背景颜色 */
}

.register-button:not(:disabled):active {
  color: white;
  background-color: #2a3daa; /* 按钮被按下时背景颜色 */
}

.close-button {
  position: absolute;
  right: -10px;
  top: -5px;
  background: none;
  border: none;
  font-size: 24px;
  line-height: 1;
  color: #666;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
  font-family: Arial, sans-serif;
}

.close-button:hover {
  color: #333;
}

.close-button:focus {
  outline: none;
}
</style>
